<template>
  <div>
    <v-card>
      <!--       <v-toolbar flat height="auto" class="custom-table-toolbar">
        <v-toolbar-items class="d-flex align-center" :class="{'flex-wrap': $vuetify.breakpoint.width < 1280}" style="width: 100%">
          <v-switch
            class="mr-5 mt-2 mb-3"
            v-if="enableSelectToggle"
            v-model="tableTickboxToggle"
            inset
            dense
            hide-details
            label="啟用勾選"
          ></v-switch>
          <v-switch
            class="mr-5"
            v-if="enableWeekdayToggle"
            v-model="tableWeekdayToggle"
            inset
            dense
            hide-details
            label="顯示禮拜"
          ></v-switch>
          <v-text-field
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            v-if="enableSearch"
            v-model="searchText"
            :append-icon="mdiMagnify"
            :label="searchPlaceholder"
            single-line
            outlined
            dense
            hide-details
            clearable
            @click:clear="clearSearch()"
            @click:append="clickSearch()"
            @keydown.enter="clickSearch()"
            @input="inputSearch()"
          ></v-text-field>
          <v-menu
            style="width: 100%; max-width: 320px"
            v-if="enableDateRange"
            ref="dateRangeMenu"
            v-model="dateRangeMenu"
            :close-on-content-click="false"
            :return-value.sync="searchDateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-3 mt-2 mb-3"
                style="width: 100%; max-width: 320px"
                :value="displayCustomDateRange(searchDateRange)"
                :label="dateRangePlaceholder"
                :append-icon="mdiCalendarSearch"
                single-line
                outlined
                dense
                hide-details
                clearable
                readonly
                @click:clear="clearDateRange()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDateRange"
              range
              no-title
              scrollable
              :first-day-of-week="0"
              :day-format="Formatter.dayFormat"
              locale="zh-hk"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                color="error"
                @click="dateRangeMenu = false"
              >
                <v-icon class="mr-1" small>{{ mdiClose }}</v-icon>
                取消
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                @click="$refs.dateRangeMenu.save(searchDateRange);selectDateRange()"
              >
                <v-icon class="mr-1" small>{{ mdiCheck }}</v-icon>
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-if="enableFilter"
            v-model="tableFilterModel"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            :items="tableFilterData"
            :menu-props="{ offsetY: true }"
            outlined
            hide-details
            dense
            @change="changeFilter()"
          ></v-select>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center">
          <v-btn v-if="enableClearAll" text small color="error" @click="clickClearAll()" plain>
            <v-icon class="mr-1" color="error">{{ mdiClose }}</v-icon>
            清除所有搜尋
          </v-btn>
          <v-btn v-if="enableRefresh" text small color="primary" @click="clickRefresh()" :plain="!$vuetify.theme.dark">
            <v-icon class="mr-1" color="primary">{{ mdiRefresh }}</v-icon>
            重新載入
          </v-btn>
        </v-toolbar-items>
      </v-toolbar> -->
      <v-data-table
        class="custom-table"
        locale="zh-hk"
        :loading="isLoading"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="pageLimit"
        :page="page"
        :server-items-length="itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle && tableTickboxToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="disableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="disableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: '資料排序',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: enableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: 'Rows per page:',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: 'All',
          pageText:
            'Show ' +
            (tableData.length > 1
              ? (page - 1) * pageLimit + 1 + '-' + Math.min(page * pageLimit, itemTotal)
              : (page - 1) * pageLimit + 1) +
            ' Items',
        }"
        loading-text="Processing"
        no-data-text="No Data"
        no-results-text="No Data"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="enableFirstLastPage"
            :items-per-page-text="''"
            :page-text="
              'Total ' +
              (itemTotal % pageLimit == 0 ? itemTotal / pageLimit : parseInt(itemTotal / pageLimit) + 1) +
              ' pages / ' +
              itemTotal +
              ' items '
            "
            @update:options="updateOptions"
          >
          </v-data-footer>
        </template>

        <!-- TODO: start -->
        <template v-slot:[`item.registration_date`]="{ item }">
          <span>{{ item.registration_date | format_date }}</span>
        </template>

        <template v-slot:[`item.actionView`]="{ item }">
          <v-btn text color="primary" small :to="{ name: actionViewRouteLink, params: { id: item.id } }">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            {{ $t('general.view_detail') }}
          </v-btn>
        </template>

        <template v-slot:[`item.viewAgreementButton`]="{ item }">
          <v-btn text color="primary" small :to="{ name: 'JobPostDetail', params: { id: item.job_id } }">
            <!--             <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon> -->
            {{ $t('general.view_detail') }} {{ $t('job_post.agreement') }}
          </v-btn>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | format_status }}</span>
        </template>

              <template v-slot:[`item.timesheet_status`]="{ item }">
          <span>{{ item.timesheet_status | format_timesheet_status }}</span>
        </template>

        <template v-slot:[`item.approval_status`]="{ item }">
          <span>{{ item.approval_status | format_approval_status }}</span>
        </template>

        <template v-slot:[`item.job_type`]="{ item }">
          <span>{{ item.job_type | format_job_type }}</span>
        </template>

        <template v-slot:[`item.working_dates`]="{ item }">
          <div>
            {{
              item.hourly_flat
                ? `${item.working_dates[0]} ~ ${item.working_dates.slice(-1)}`
                : item.working_dates.join(', ')
            }}
          </div>
        </template>

        <template v-slot:[`item.hourly_flat`]="{ item }">
          <div>
            {{ item.hourly_flat ? $t('job_post.flat_rate') : $t('job_post.hourly_rate') }}
          </div>
        </template>

        <template v-slot:[`item.total_hours`]="{ item }">
          <div>
            {{
              item.hourly_flat
                ? '-'
                : globalFunc.subtractTime(item.start_time, item.end_time, item.working_dates.length)[0]
            }}
          </div>
        </template>

        <template v-slot:[`item.hourly_rate`]="{ item }">
          <div>
            {{ item.hourly_flat ? '-' : `$${globalFunc.formatMoney(item.rate)}` }}
          </div>
        </template>

        <template v-slot:[`item.flat_rate`]="{ item }">
          <div>
            {{ item.hourly_flat ? `$${globalFunc.formatMoney(item.rate)}` : '-' }}
          </div>
        </template>

        <template v-slot:[`item.news_title_zh`]="{ item }">
          <div>
            {{ globalFunc.DataValid(item.news_title_zh) ? item.news_title_zh : '-' }}
          </div>
        </template>

        <template v-slot:[`item.news_title_en`]="{ item }">
          <div>
            {{ globalFunc.DataValid(item.news_title_en) ? item.news_title_en : '-' }}
          </div>
        </template>

        <template v-slot:[`item.news_image`]="{ item }">
          <div class="my-4">
            <v-img :src="`${MEDIA_PATH}${item.news_image}`" max-width="250"></v-img>
          </div>
        </template>

        <template v-slot:[`item.payment_method`]="{ item }">
          <span>{{ item.payment_method | format_payment_method }}</span>
        </template>

        <template v-slot:[`item.create_date`]="{ item }">
          {{ item.create_date.split('T')[0] }}
        </template>

        <template v-slot:[`item.complete_date`]="{ item }">
          <div>
            {{ globalFunc.DataValid(item.complete_date) ? globalFunc.getDateStr(item.complete_date) : '-' }}
          </div>
        </template>

        <!-- TODO: end -->

        <template v-slot:[`item.actionDetails`]="{ item }">
          <v-btn text color="secondary" small @click="emitDetailsClickEvent(item.id)">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>
        <template v-slot:[`item.actionDelete`]="{ item }">
          <v-btn text color="error" small @click="presentConfirmDeleteDialog(item.data_type, item.id)">
            <v-icon class="mr-1" small>{{ mdiTrashCanOutline }}</v-icon>
            刪除
          </v-btn>
        </template>
        <template v-slot:[`item.actionDetailsDelete`]="{ item }">
          <v-btn text color="secondary" small @click="emitDetailsClickEvent(item.id)">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
          <v-btn text color="error" small @click="presentConfirmDeleteDialog(item.data_type, item.id)">
            <v-icon class="mr-1" small>{{ mdiTrashCanOutline }}</v-icon>
            刪除
          </v-btn>
        </template>
        <template v-slot:[`item.actionViewDelete`]="{ item }">
          <v-btn text color="secondary" small :to="{ name: actionViewRouteLink, params: { id: item.id } }">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
          <v-btn text color="error" small @click="presentConfirmDeleteDialog(item.data_type, item.id)">
            <v-icon class="mr-1" small>{{ mdiTrashCanOutline }}</v-icon>
            刪除
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogConfirmDelete.show" content-class="table-dialog" persistent max-width="240">
      <v-card>
        <v-card-title color="error" class="text-subtitle-1 text-center justify-center">
          <span class="error--text">
            <v-icon color="error" class="mr-1">{{ mdiAlertOutline }}</v-icon>
            確定刪除資料？
          </span>
        </v-card-title>
        <v-card-text class="text-center">
          {{ confirmDeleteDes }}
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            :loading="dialogConfirmDelete.loading"
            :disabled="dialogConfirmDelete.loading"
            @click="deleteItem()"
          >
            <v-icon class="mr-1" small>{{ mdiDeleteForever }}</v-icon>
            確認刪除
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="dialogConfirmDelete.loading"
            :disabled="dialogConfirmDelete.loading"
            @click="dialogConfirmDelete.show = false"
          >
            <v-icon class="mr-1" small>{{ mdiCancel }}</v-icon>
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar top right v-model="dataDeleted" color="success" :timeout="3000">
      <v-icon class="mr-2" color="white">{{ mdiCheckCircle }}</v-icon>
      成功刪除資料
    </v-snackbar>
    <v-snackbar top right v-model="dataFailedToDelete" color="error" :timeout="3000">
      <v-icon class="mr-2" color="white">{{ mdiAlert }}</v-icon>
      無法刪除資料
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable no-else-return */
import {
  mdiEyeOutline,
  mdiTrashCanOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiCancel,
  mdiCheck,
  mdiClose,
  mdiDeleteForever,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiNotePlusOutline,
} from '@mdi/js'

import { globalFunc, MEDIA_PATH } from '@/global'
export default {
  // ------ page properties ------
  name: 'CorrectTable',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableFilterData: {
      type: Array,
      default: () => [{ text: '全部', value: 'all' }],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableWeekdayToggle: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: true,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableFilter: {
      type: Boolean,
      default: false,
    },
    enableDateRange: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableFirstLastPage: {
      type: Boolean,
      default: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disableFiltering: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [5, 10, 20, 50],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '搜尋',
    },
    dateRangePlaceholder: {
      type: String,
      default: '按日期範圍搜尋',
    },
    confirmDeleteDes: {
      type: String,
      default: '此動作將無法還原',
    },
  },
  // ------ page variables ------
  data: () => ({
    globalFunc: globalFunc,
    MEDIA_PATH: MEDIA_PATH,
    tableInit: false,
    tableWeekdayToggle: true,
    tableTickboxToggle: false,
    tableFilterModel: 'all',
    options: {},
    dialogConfirmDelete: {
      show: false,
      loading: false,
      data_type: '',
      data_id: -1,
    },
    searchText: '',
    searchDateRange: [],
    dateRangeMenu: false,
    dataDeleted: false,
    dataFailedToDelete: false,
    examStatus: [
      { text: '合格', value: 'pass' },
      { text: '不合格', value: 'failed' },
      { text: '沒有出席', value: 'absent' },
      { text: '未完成', value: 'incomplete' },
      { text: '未回覆', value: 'pending' },
      { text: '已拒絕', value: 'refused' },
    ],
    staffRole: [
      { text: 'Master Admin', value: 'superadmin' },
      { text: 'Admin', value: 'admin' },
      { text: 'Tutor', value: 'tutor' },
    ],
    tutorOptions: [
      { text: '駕駛改進課程', value: '3_point' },
      { text: '強制試理論課', value: 'competence_theory' },
      { text: '強制試訓練', value: 'competence_practical' },
      { text: '電單車', value: 'motor' },
      { text: '電單車理論課', value: 'motor_theory' },
      { text: '電單車模擬考試', value: 'motor_mock' },
      { text: '汽車', value: 'car' },
      { text: '汽車理論課', value: 'car_theory' },
      { text: '汽車模擬考試', value: 'car_mock' },
    ],
    // icon
    mdiEyeOutline,
    mdiTrashCanOutline,
    mdiAlertOutline,
    mdiCancel,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiRefresh,
    mdiMagnify,
    mdiCalendarSearch,
    mdiNotePlusOutline,
    mdiCheckCircle,
    mdiAlert,
  }),
  // ------ mutation observer ------
  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
  },
  // ------ life cycle events ------
  created() {},
  // ------ page functions ------
  methods: {
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.Formatter.displayWeekday(date[1])}) ~ ${date[0]} (${this.Formatter.displayWeekday(
            date[0],
          )})`
        } else {
          return `${date[0]} (${this.Formatter.displayWeekday(date[0])}) ~ ${date[1]} (${this.Formatter.displayWeekday(
            date[1],
          )})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.Formatter.displayWeekday(date[0])})`
      }
      return ''
    },
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickSearch() {
      this.$emit('search-clicked', this.searchText)
    },
    inputSearch() {
      this.$emit('search-input', this.searchText)
    },
    clearSearch() {
      this.$emit('search-cleared')
    },
    selectDateRange() {
      if (this.searchDateRange.length === 2) {
        if (new Date(this.searchDateRange[0]).getTime() > new Date(this.searchDateRange[1]).getTime()) {
          this.$emit('daterange-selected', [this.searchDateRange[1], this.searchDateRange[0]])
        } else {
          this.$emit('daterange-selected', this.searchDateRange)
        }
      } else {
        this.$emit('daterange-selected', this.searchDateRange)
      }
    },
    clearDateRange() {
      this.searchDateRange = []
      this.$emit('daterange-cleared')
    },
    clickClearAll() {
      this.searchText = ''
      this.searchDateRange = []
      this.tableFilterModel = 'all'
      this.$emit('all-search-cleared')
    },
    changeFilter() {
      this.$emit('filter-changed', this.tableFilterModel)
    },
    presentConfirmDeleteDialog(dataType, dataId) {
      this.dialogConfirmDelete.data_type = dataType
      this.dialogConfirmDelete.data_id = dataId
      this.dialogConfirmDelete.show = true
    },
    emitDetailsClickEvent(dataId) {
      this.$emit('details-clicked', dataId)
    },
    deleteItem() {
      const vm = this
      vm.dialogConfirmDelete.loading = true
      vm.XHR.api(
        {
          // payload
          delete_data_by_data_type_and_id: JSON.stringify({
            data_type: vm.dialogConfirmDelete.data_type,
            id: vm.dialogConfirmDelete.data_id,
          }),
        },
        (result, data) => {
          // success callback
          vm.dialogConfirmDelete.data_type = ''
          vm.dialogConfirmDelete.data_id = -1
          vm.dialogConfirmDelete.loading = false
          vm.dialogConfirmDelete.show = false
          vm.dataDeleted = true
          setTimeout(() => {
            vm.$emit('item-deleted', {
              data_type: vm.dialogConfirmDelete.data_type,
              data_id: vm.dialogConfirmDelete.data_id,
            })
          }, 100)
        },
        (result, error) => {
          // failed callback
          console.warn(error)
          vm.dialogConfirmDelete.loading = false
          vm.dataFailedToDelete = true
        },
      )
    },
    clickExamAssessment(item) {
      this.$emit('exam-assessment-clicked', item)
    },
  },
}
</script>

<style>
.v-data-table .v-data-table-header-mobile .v-text-field .v-select__selections {
  margin-top: 0.5rem;
}
.custom-table-toolbar .v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.875rem;
}
.custom-table-toolbar .v-input input,
.custom-table-toolbar .v-input label {
  font-size: 0.825rem;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  padding-top: 1rem;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:last-of-type {
  padding-bottom: 1rem;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 2.2rem;
}
.hide-page-select .v-data-footer__select {
  visibility: none;
  opacity: 0;
  pointer-events: none;
}
.v-dialog.table-dialog {
  margin: 0 auto;
}
</style>